.services_each_card {
  background-color: #494653;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  transition: all 0.5s;
  margin: 1vh;
}
.arac-ozellik-bg table thead tr{
  background-color: #ef3c26;
  border-radius: 10px;
}
.services_each_card h5,
.services_each_card p {
  color: white;
}
.sarj-main img:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.arac-sarj-main .table tr th{
  color: white !important;
}
.border-table-first{
  border-color: transparent !important;
}
.services-form input{
  border-radius: 10px !important;
  border: 1px solid black;
}
.services-form select{
  border-radius: 10px !important;
}
.services-form textarea{
  border-radius: 10px !important;
}
.services-inner .services_each_card .button-type {
  z-index: 1;
  background: transparent;
  position: relative;
  transition: all 0.4s;
  overflow: hidden;
  color: white;
  opacity: 0;
  transition: all 0.4s, opacity 0.1s 0s;
}

.services_each_card:hover .button-type {
  transition: all 0.4s, opacity 0.1s 0s;
  opacity: 1;
}

.services_each_card .button-type::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #ef3c26;
  z-index: -1;
  transition: all 0.4s;
}
.services_each_card .button-type:hover::before {
  width: 100%;
}

.bg-articles-card .button-type {
  z-index: 1;
  background: transparent;
  position: relative;
  transition: all 0.4s;
  overflow: hidden;

  color: white;
  opacity: 1;
  transition: all 0.4s, opacity 0.1s 0s;
}
.services-main .services_each_card {
  margin: 0;
  width: min-content;
}
.bg-articles-card:hover .button-type {
  transition: all 0.4s, opacity 0.1s 0s;
  opacity: 1;
  -webkit-transition: all 0.4s, opacity 0.1s 0s;
  -moz-transition: all 0.4s, opacity 0.1s 0s;
  -ms-transition: all 0.4s, opacity 0.1s 0s;
  -o-transition: all 0.4s, opacity 0.1s 0s;
}

.bg-articles-card .button-type::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #ef3c26;
  z-index: -1;
  transition: all 0.4s;
}
.bg-articles-card .button-type:hover::before {
  width: 100%;
}
.back-icon svg {
  color: #ef3c26;
  font-weight: 900;
  font-size: 40px;
  background-color: #494653;
  border-radius: 10px;
  cursor: pointer;
}
.slide-right .service-cards {
  transform: translateX(-38%);
  transition: transform 0.8s ease-in-out;
  overflow: visible;
  -webkit-transform: translateX(-38%);
  -moz-transform: translateX(-38%);
  -ms-transform: translateX(-38%);
  -o-transform: translateX(-38%);
}
.enterprise-main .service-cards {
  transform: translateX(-5vw);
  -webkit-transform: translateX(-5vw);
  -moz-transform: translateX(-5vw);
  -ms-transform: translateX(-5vw);
  -o-transform: translateX(-5vw);
  transition: transform 0.8s ease-in-out;
  height: 17vh;
}
.main-desc {
  position: relative;
  top: 5vh;
  transition: top 0.3s ease-in-out;
  cursor: pointer;
}
.services_each_card:hover .main-desc {
  top: 0vh;
  transition: top 0.3s ease-in-out;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.services-inner-button-type-four {
  position: relative;
  border: 3px solid #ef3c26;
  border-radius: 10px;
  background-color: #ef3c26;
  padding: 1vh 2vh;
  overflow: hidden;
  color: white;
  width: max-content;
}

.button-type-four:hover {
  background-position: -100% 0;
  border-radius: 10px;
  background-color: #ef3c26;
  padding: 1vh 2vh;
  color: white;
}

.swiper-slide .services_each_card {
  height: 90%;
}
.swiper-pagination-bullet {
  background: #ef3c26 !important;
}
.services-radius {
  border-radius: 0px 0px 0px 15px;
  margin-top: -1px;
}
.swiper-wrapper {
  width: 100vw !important;
}
.services_each_card {
  width: min-content;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none;
}
@media screen and (max-width: 767px) {
  .inner-container .large-services {
    display: none !important;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    display: block;
  }
  .services-radius {
    border-radius: 0px 0px 15px 15px;
  }
  /* .swiper-backface-hidden .swiper-slide {
    width: 34vw !important;
  } */
  .swiper-wrapper {
    width: 350vw !important;
  }
  .enterprise-main .small-services .swiper-backface-hidden .swiper-slide {
    margin-right: 0px !important;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    left: 0 !important;
  }
  .services_each_card {
    width: 32vw;
  }
}
@media screen and (max-width: 991px) {
  .services-radius {
    border-radius: 0px 0px 15px 15px;
  }
}

@media screen and (min-width: 992px) {
  .small-services {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .main-desc {
    top: 3.8vh;
  }
  .services_each_card {
    width: 16vw;
  }
  .inner-container .large-services {
    display: none !important;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    display: block;
  }
  .small-services .services_each_card {
    width: 20vw;
  }
}
@media screen and (min-width: 992px) {
  .small-services {
    display: none;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1363px) {
  .large-services {
    display: none !important;
  }
}
@media screen and (min-width: 1700px) {
  .enterprise-main.slide-right .service-cards {
    transform: translateX(-27%);
    -webkit-transform: translateX(-27%);
    -moz-transform: translateX(-27%);
    -ms-transform: translateX(-27%);
    -o-transform: translateX(-27%);
  }
}

@media (min-height: 830px) and (max-height: 1200px) and (min-width: 1700px) {
  .enterprise-main.slide-right .service-cards {
    transform: translateX(-45%) !important;
    -webkit-transform: translateX(-45%) !important;
    -moz-transform: translateX(-45%) !important;
    -ms-transform: translateX(-45%) !important;
    -o-transform: translateX(-45%) !important;
  }
}
@media (min-height: 830px) and (max-height: 985px) {
  .enterprise-main.slide-right .service-cards {
    transform: translateX(-43%);
    -webkit-transform: translateX(-43%);
    -moz-transform: translateX(-43%);
    -ms-transform: translateX(-43%);
    -o-transform: translateX(-43%);
  }
}
@media (min-height: 985px) and (max-height: 1440px) and (max-width: 1700px) {
  .enterprise-main.slide-right .service-cards {
    transform: translateX(-48%);
    -webkit-transform: translateX(-48%);
    -moz-transform: translateX(-48%);
    -ms-transform: translateX(-48%);
    -o-transform: translateX(-48%);
  }
}

.bg-services .swiper-backface-hidden .swiper-slide {
  backface-visibility: hidden;
  width: 170px !important;
  height: 15vh;
  display: flex;
  margin: 0 1vh;
  justify-content: end;

  -webkit-backface-visibility: hidden;
}
.bg-services .swiper {
  height: auto !important;
  overflow: initial !important;
}
.services-link {
  text-decoration: none;
  color: black;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.services-link:hover {
  color: #ef3c26;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
