.bg-enterprise {
  background-image: url("../images/kurumsal-back.png");
  background-size: cover;
  background-repeat: no-repeat;

  /* background-position: left 10px right 10px; */

}
.bg-services {
  background-image: url("../images/services.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.enterprise-main {
  border-radius: 15px;
  height: 55vh;
  padding: 1vh;
}
.enterprise-radius {
  border-radius: 0px 15px 0px 0px;
  margin-bottom: -1px;
}
.enterprise-main-inner {
  border-radius: 15px;
  padding: 2vh;
  height: 76vh;
}
.bg-enterprise-main {
  background-image: url("../images/vid_01.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.blog-main {
  border-radius: 15px;
  padding: 2vh;
  height: auto;
}
.h_160 {
  height: 100%;
}
.articles-radius {
  border-radius: 15px 0px 0px 0px;
    margin-bottom: -1px;
 
}
.experince-single .swiper-button-prev, .swiper-button-next{
top: 40% !important;
}
@media screen and (max-width: 766px) {
  .experince-single .swiper-button-prev, .swiper-button-next{
    top: 30% !important;
    }
  .enterprise-main {
    height: 65vh;
  }
  .enterprise-radius {
    border-radius: 15px 15px 0px 0px;
  }
  .enterprise-main-inner {
    height: 100%;
  }
 .articles-page .enterprise-main{
    height: 100%;
  }
  .contact-page .enterprise-main{
    height: 100%;
  }
  /* .h_160{
        height: 100vh;
    } */
  .articles-radius {
    border-radius: 15px 15px 0px 0px;
    margin-bottom: -1px;
  }
}
@media screen and (min-width: 767px) and (max-width: 992px) {
  .enterprise-main-inner {
    height: 100%;
  }
  .contact-page .enterprise-main{
    height: 100%;
  }
  .enterprise-radius {
    border-radius: 15px 15px 0px 0px;
  }
  .articles-radius {
    border-radius: 15px 15px 0px 0px;
  }
}
@media screen and (min-width: 993px) and (max-width: 1066px) {
  .enterprise-main {
    height: 45vh;
  }
}
@media screen and (min-width: 767px) and (max-width: 1000px) {
  .contact-page .enterprise-main{
    height: 100%;
  }
  .articles-page .enterprise-main{
    height: 100%;
  }
}