.contact-page input{
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: 2px solid #ef3c26 !important;
}
.map-main{
    height: 100%;

}
.map-inner-main{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.contact-radius{
  border-radius: 0px 15px 0px 0px;
}
.contact-page a{
    color: black;
    text-decoration: none;
}
@media screen and (max-width: 766px) {
  
    .map-inner-main{
        height: 300px;
    }
    .contact-radius{
        border-radius: 0px 0px 15px 15px;
        margin-top: -1px;
      }
}

@media screen and (min-width: 767px) and (max-width: 992px) {
    .map-main{
        height: 100%;
    }
    .map-inner-main{
        height: 500px;
 
    }
    .contact-radius{
        border-radius: 0px 0px 15px 15px
      }
}
