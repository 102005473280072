.card-type-three{
    background-color: #EF3C26;
    border-radius: 10px;
    margin-right: 1vh;
    padding: 0.8vh 1.2vh;
}
.card-type-three svg{
    color: white;
}
.footer img{
    max-width: 100%;
  }
  .footer-main a{
    color: black;
    text-decoration: none;
  }
  .footer h6{
    font-weight: 900;
  }
  .footer-bottom-line{
    border-bottom: 1px solid #8080807d;
  }
  .footer-bottom-line a{
    color: #8080807d !important;
    text-decoration: none !important;
  }
  .footer .footer-main p{ 
    font-size: 0.8rem;

  }
  .madeby_text{
    color: #8080807d;
  }
  .osicrew-img{
    padding: 0vh 1vh;
  }
 .osicrew-img{
  height: 3.2vh;
 }
 .footer-bottom-line p{
  margin-bottom: 0rem;
 }
 .madeby_text p{
  margin-bottom: 0rem;
 }