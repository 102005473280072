.rfm-child{
    padding:0vh 5vh !important;
}
.rfm-marquee-container{
    justify-content: start !important;
    align-items: start !important;
    padding: 0 !important;
}
.bg-experience{
    background-image: url("../images/img_03.png");
    background-size: cover;
    background-repeat: no-repeat;
}
.experience-radius{
   border-radius: 0px 0px 15px 0px;
   margin-top:-1px
}
.experince-single .swiper-slide img{
    border-radius: 25%;
}
.experince-single .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
    color: #EF3C26;
    font-size: 2rem;
    font-weight: 900;
}
.experince-single .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
    color: #EF3C26;
    font-size: 2rem;
    font-weight: 900;
}
.experince-single .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: 35px;
}
.experince-single .swiper-button-prev, .swiper-rtl .swiper-button-next{
    left: 35px;
}
.experince-single .swiper-slide{
display: flex;
}
.experince-single .swiper-backface-hidden .swiper-slide{
    margin-right: 0 !important;
}
@media screen and (max-width: 766px) {
    .experience-radius{
        border-radius: 0px 0px 15px 15px;
     
     }
  }
@media screen and (min-width: 767px) and (max-width: 992px) {
    .experience-radius{
        border-radius: 0px 0px 15px 15px
     }
    
  }