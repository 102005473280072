.counter-item {
  position: relative;
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* Add this line */
}

.counter-img {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: #ededed;
  padding: 0vh 1.5vh;
 height: 3.5vh;

}
.counter-page p{
  width: max-content;
}
.img-radius-counter{
  border-radius: 50%;
}
.counter-item::before {
  content: "";
  position: absolute;
  width: 250px;
  height: 250px;
  border: 4px solid #ef3c26;
  border-radius: 50%;
  top: -13px;
}
.counter-title{
  font-weight: 800;
  font-size: 2rem;
}
@media screen and (max-width: 766px) {
  .counter-item::before{
    width: 190px;
    height: 190px;
  }
  .counter-title b{
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 767px) and (max-width:1224px) {
  .counter-img {
 
    padding: 0vh 1vh;
   height: 3vh;
  
  }
  .counter-item::before {
   
    width: 200px;
    height: 200px;
  
  }
  
  .counter-item{
    padding: 23px;
  }
  }