@font-face {
  font-family: Objectiv Mk1;
  src: url(../src/assets/fonts/ObjektivMk1_Trial_Rg.woff);
}
body {
  background-color: #ededed;
}
:root {
  background-color: #ededed !important;
}
.App {
  text-align: center;
  font-family: Objectiv Mk1;
  background-color: #ededed;
  overflow: hidden;
}
.table svg{
  fill: #ef3c26;
  font-size: 1.5rem;
  font-weight: 800;
}
.arac-sarj-main .table tr th{
  width: max-content;
  text-align:start
}
.services_each_card .button-type {
  z-index: 1;
  background: transparent;
  position: relative;
  transition: all 0.4s;
  overflow: hidden;
  color: white;
  opacity: 0 ;
  transition: all 0.4s, opacity 0.1s 0s;
}

.services_each_card:hover .button-type {
  transition: all 0.4s, opacity 0.1s 0s;
  opacity: 1;
}

.services_each_card .button-type::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #ef3c26;
  z-index: -1;
  transition: all 0.4s;
}
.services_each_card .button-type:hover::before {
  width: 100%;
}
.services_each_card:hover .button-type-card-2 {
  transition: all 0.4s, opacity 0.1s 0s;
  opacity: 1;
}

.services_each_card ::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #ef3c26;
  z-index: -1;
  transition: all 0.4s;
}
.services_each_card .button-type-card-2:hover::before {
  width: 100%;
}

.inner-container {
  border-radius: 10px;
  height: 100%;
}

.button-type {
  color: black;
  position: relative;
  border: 3px solid #ef3c26;
  border-radius: 10px;
  background-color: white;
  padding: 1vh 2vh;
  width: max-content;
  overflow: hidden;
  transition: background-position 0.5s ease;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, white 50%, #ef3c26 50%);
}
.button-type:hover {
  background-position: -100% 0;
  border-radius: 10px;
  background-color: #ef3c26;
  padding: 1vh 2vh;
  color: white;
}

.button-type-four {
  width: max-content;
  position: relative;
  border: 3px solid #ef3c26;
  border-radius: 10px;
  background-color: #494653;
  padding: 1vh 2vh;
  overflow: hidden;
  color: white;
  transition: background-position 0.5s ease;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, #494653 50%, #ef3c26 50%);
}
.button-type-four:hover {
  background-position: -100% 0;
  border-radius: 10px;
  background-color: #ef3c26;
  padding: 1vh 2vh;
  color: white;
}
.loader-bg {
  background-image: url("../src/assets/images/pattern.svg");
  z-index: 99999;
  height: 100vh;
}

.loader {
  height: 100vh;
}
.downing-page-div {
  background-color: #ededed;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1 !important;
  z-index: 1 !important;
}
.loader .loder-images {
  background-repeat: no-repeat;
  background-image: url("../src/assets/images/pattern.svg");
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-gray {
  background-color: #ededed;
}
.bg-white {
  background-color: white;
}
.h_38 {
  height: 36vh;
  border-radius: 12px;
}
.h_36 {
  height: 35vh;
}
.card-type {
  background-color: #ffffff42;
  backdrop-filter: blur(5px);
  border-radius: 12px;
  padding: 1vh;
  margin: 1.3vh;
}
.h_100 {
  height: 75vh;
}
.w-20 {
  width: 20%;
}
.card-type-two {
  background-color: white;
  border-radius: 12px;
  padding: 1vh;
  height: 100%;
}
.border-radius-style {
  border-radius: 12px;
}
.card-type-three {
  background-color: #ef3c26;
  border-radius: 10px;
  margin-right: 1vh;
  padding: 0.8vh;
}
.h_10 {
  height: 10vh;
}
img {
  max-width: 100%;
}
.card-type-four {
  background-color: #ffffff3b;
  backdrop-filter: blur(10px);
}
.main-radius {
  border-radius: 12px;
}
.bg-articles {
  background-image: url("../src/assets/images/img_04.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-faq {
  background-image: url("../src/assets/images/img_05.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-contact {
  background-image: url("../src/assets/images/img_06.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.loaderes {
  position: fixed !important;
  height: 100vh;
  top: 0;
  left: 0;
}
.bg-deka {
  background-color: #ef3c26;
  border-radius: 0px 0px 30px 30px;
}
.border-none {
  border: none !important;
}

input:focus-visible {
  outline-offset: none !important;
  outline: none !important;
}

textarea:focus-visible {
  outline-offset: none !important;
  outline: none !important;
}
.header-main {
  height: 14vh;
}
.button-type-five {
  color: black;
  position: relative;
  border: 3px solid #ef3c26;
  border-radius: 10px;
  background-color:  #ededed;
  padding: 1vh 2vh;
  overflow: hidden;
  transition: background-position 0.5s ease;
  background-size: 200% 100%;
  background-image: linear-gradient(to right,  #ededed 50%, #ef3c26 50%);
}
.button-type-five:hover {
  background-position: -100% 0;
  border-radius: 10px;
  background-color: #ef3c26;
  padding: 1vh 2vh;
  color: white;
}
.table {
  --bs-table-bg:transparent !important
}
 tr{
  border-color: black !important;
}

@media screen and (max-width: 766px) {
  
  .enterprise-main{
    height: 100%;
  }
  .button-type:hover {
    padding: 0.5vh;
  }
  .button-type {
    padding: 0.5vh;
  }
  .header-logo {
    height: 70%;
    position: relative;
    z-index: 99;
  }
  .loader-img {
    height: 10vh;
  }
  h1 {
    font-size: 1.3rem !important;
  }

  h5 {
    font-size: 1rem !important;
  }
  p {
    font-size: 0.9rem !important;
  }
  .header-main {
    height: 12vh;
  }
}

@media screen and (max-width: 768px) {
  .scroll-to-top {
    display: none;
  }
}

@media screen and (min-width: 1800px) {
  .container {
    max-width: 1900px !important;
  }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .enterprise-main{
    height: 100%;
  }
    h1 {
      font-size: 1.3rem !important;
    }
  
    h5 {
      font-size: 1rem !important;
    }
    p {
      font-size: 0.9rem !important;
    }
  
  }       

  @media screen and (max-height: 550px) and (orientation: landscape) {
    .landscape-overlay {
        display: flex;
        height: 100vh;
        position: sticky;
        top: 0;
        background-color: black;
        width: 100%;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

@media screen and (max-height: 550px) and (orientation: portrait) {
    .landscape-overlay {
        display: none;
    }
}

@media screen and (min-height: 551px) {
    .landscape-overlay {
        display: none;
    }
}