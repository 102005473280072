.bottom-bar ul li a {
  text-decoration: none;
  color: #000;
}

@media screen and (max-height: 300px) {
  ul {
    margin-top: 40px;
  }
}

.menu {
  position: fixed;
  bottom: 0;
  left: auto;
  z-index: 1;
  background-color: #fff !important;
  border-radius: 15px;
  border: none;
  text-align: left;
  padding: 10px 40px;
}

.menu::before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 10%;
  background-color: #ef3c26;
  z-index: -1;
  transition: width 0.3s;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu .menu-item {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  text-align: center;
}

.menu.one::before {
  width: 30%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  text-align: center;
}

.menu.two::before {
  width: 44%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.menu.three::before {
  width:58%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.menu.four::before {
  width: 70%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.menu.five::before {
  width: 82%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.menu.six::before {
  width: 100%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.menu.seven::before {
  width: 100%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.menu-item a {
  color: #23201f;
  text-decoration: none;
  width: 100%;
}

.active {
  color: #ededed !important;
}

.active a {
  color: #ededed !important;
}

.active:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
@media screen and (max-width: 991px) {
  .bottom-bar-main {
    display: none !important;
  }
  .scroll-to-top {
    display: none;
  }
}
.menu.zero::before {
  width: 18%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  text-align: center;
}
