.mobile-menu {
  position: fixed;
  top: 1vh;
  right: 0;
  background-color: transparent;
  color: black;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 20px;
  z-index: 1000;
}

.toggle-container {
  font-size: 2rem;
  cursor: pointer;
  color: black;
  position: relative;
  z-index: 99999;
}

.menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff45;
  backdrop-filter: blur(15px);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  transition: transform 0.5s ease;
  transform: translateY(-100%);
}
.menu-content a{
  color: white !important;
  text-decoration: none !important;
}
.toggle-container.white {
  color: white;
}
.menu-content.open {
  transform: translateY(0);
}
.mobile-inner-section {
  background-color: #ef3c26;
border-radius: 20vh;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mobile-inner-section ul li {
  list-style: none;
  color: white;
  font-weight: 700;
  line-height: 4.5vh;
}
.mobile-inner-section ul {
  padding-left: 0rem;
  text-align: start;
}
@media screen and (min-width: 992px) {
    .mobile-menu{
        display: none;
    }
  }

  @media screen and (min-width: 820px) {
    .menu-content a{
    font-size: 1.3rem;
    }
  }