.sss-bg-inner {
    background-image: url("../images/img_05.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .faq-border {
    border: 1px solid black;
  }
  .send-icon {
    background-color: #ef3c26;
    border-radius: 10px;
    width: max-content;
    padding: 0.6vh 1.2vh;
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    cursor: pointer
   
  }
  .faq-sss{
  border-radius: 15px 15px 0px 0px
  }
  .send-icon svg {
    color: white;
  }
  .faq-input {
     /* height: 7vh;  */
    border: 1px solid black;
  }
 
  .rounded-lg p{
    padding-left: 0.3vh;
    margin: 0;

  }
 .chat-container{

  overflow-y: auto;
 }
  .loading_message {
    text-align: center;
    font-size: 30px;
  }
  .h_120{
    height: 75vh;
  }

  .faq-sss{
    margin-bottom: -1px;
  }



  @media screen and (max-width: 766px) {
    .faq_help{
        bottom: 65%;
    }
    .h_120{
        height: 100vh;
        margin-top: 3vh;
    }
  
}

@media screen and (min-width: 767px) and (max-width: 992px) {
    .faq_help{
        bottom: 55%;
    }
    .h_120{
       
        margin-top: 3vh;
    }
}